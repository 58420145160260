<template>
  <div :class="classCard + 'card-map'" @click="action">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CardMap",
  props: {
    classCard: { type: String, default: "" },
  },
  methods: {
    action() {
      this.$emit("goToInstallation");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Card";
</style>
