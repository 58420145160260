<script src="https://maps.googleapis.com/maps/api/js?key='AIzaSyA7p9dsEBjH7LhKjpCpgAlPMmLFDrafII8'"></script>

<template>
  <div class="map">
    <Map
      :userMarker="userMarker"
      :markers="markers"
      @goToInstallation="goToInstallation($event)"
    />
    <div class="map-content">
      <CardMap classCard="main-card ">
        <div class="location-card row">
          <gmap-autocomplete
            @place_changed="setCurrentPlace"
            :options="{
              fields: ['geometry', 'formatted_address', 'address_components'],
            }"
            class="map-input"
            placeholder="Morada"
          >
          </gmap-autocomplete>
          <img :src="require('@/assets/search.svg')" />
        </div>
        <div class="location-card" @click="checkLocationPermission">
          <img :src="require('@/assets/current-location.svg')" />
          <p class="card-btn">Utilizar a minha localização atual</p>
        </div>
      </CardMap>
      <CardMap
        v-if="filteredInstallations.length != 0"
        classCard="filter-card noselect "
      >
        <p class="card-btn row" @click="showFilter">
          <span>Selecionar Serviços</span>
          <img :src="require('@/assets/red-arrow.svg')" />
        </p>
        <div v-if="visibleFilter" class="filter-list row">
          <div
            v-for="(service, index) in services"
            :key="index"
            class="checkbox-container row"
          >
            <input
              type="checkbox"
              :id="service.serviceId"
              :value="service.serviceId"
              class="checkbox"
              :checked="arrayFilter.includes(service.serviceId)"
              @change="updateArrayFilter($event)"
            />
            <label :for="service.serviceId" class="checkbox-label row">
              <img :src="service.serviceIcon" alt="" />
              <span>{{ service.serviceDesignation }}</span>
            </label>
          </div>
          <ButtonCTA
            btnLabel="Aplicar Filtros"
            @action="applyFilters"
            btnClass="primary-btn full-width-btn "
          />
        </div>
      </CardMap>
      <div class="installations-results">
        <CardMap
          v-for="(installation, index) in filteredInstallations"
          :key="index"
          classCard="installation-card "
          @goToInstallation="goToInstallation(installation.dealerID)"
        >
          <div class="installation-info-container row">
            <div class="installation-number">
              <p>{{ index + 1 }}</p>
            </div>
            <div class="installation-info">
              <p class="installation-name">{{ installation.dealerFullName }}</p>
              <!-- <p class="installation-distance">
                {{ installation.dealerDistance }} km
              </p> -->
              <div class="installations-services row">
                <img
                  v-for="(service, index) in installation.dealerServices"
                  :key="index"
                  :src="getServiceIcon(service)"
                  :title="getServiceName(service)"
                />
              </div>
            </div>
          </div>
        </CardMap>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonCTA from "@/components/Buttons/ButtonCTA.vue";
import CardMap from "@/components/Card/CardMap.vue";
import Map from "@/components/Map/Map.vue";

import { getDealersByRange } from "@/functions/location.js";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "MapContainer",
  components: {
    ButtonCTA,
    CardMap,
    Map,
  },
  data() {
    return {
      services: this.$store.state.instalacoesServicos,
      installations: this.$store.getters.getNewInstallationsArray,
      sortedInstallationsByDistance: [],
      filteredInstallations: [],

      currentPlace: null,
      userMarker: { lat: 41.1177, lng: -8.596 },

      markers: [],

      visibleFilter: false,
      arrayFilter: [],
      isFirst: true,
    };
  },
  computed: {
    google: gmapApi,
  },
  created() {
    if (this.google) {
      this.checkLocationPermission();
    }
  },
  methods: {
    getServiceIcon(service) {
      const fullService = this.services.find((element) => {
        return element.serviceId == service;
      });

      return fullService ? fullService.serviceIcon : "";
    },
    getServiceName(service) {
      const fullService = this.services.find((element) => {
        return element.serviceId == service;
      });

      return fullService ? fullService.serviceDesignation : "";
    },
    checkLocationPermission() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.showError
        );
      } else {
        alert("Geolocation não é suportada neste browser.");
      }
    },
    //GET USER'S CURRENT LOCATION
    showPosition(position) {
      this.markers = [];
      this.userMarker = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      if (typeof google != "undefined") {
        this.findDealers(true);
      }
    },
    showError(error) {
      this.locationPermission = false;
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert("Bloqueou a permissão para a sua localização.");
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Informação de localização indisponível.");
          break;
        case error.TIMEOUT:
          alert("Pedido de localização falhou.");
          break;
        case error.UNKNOWN_ERROR:
          alert("Erro desconhecido.");
          break;
      }

      if (typeof google != "undefined") {
        this.findDealers();
      }
    },

    setCurrentPlace(place) {
      this.currentPlace = place;

      if (this.currentPlace) {
        this.userMarker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
      }

      this.findDealers(true);
    },

    findDealers(distance) {
      if (distance && !this.isFirst) {
        this.sortedInstallationsByDistance = getDealersByRange(
          this.installations,
          this.userMarker
        ).slice(0, 5);
      } else {
        this.sortedInstallationsByDistance = getDealersByRange(
          this.installations,
          false
        );
      }

      this.isFirst = false;

      this.applyFilters();
    },
    addMarker(installation) {
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(installation.lat, installation.lng),
        installationID: installation.dealerID,
      });

      this.markers.push(marker);
    },

    goToInstallation(installation) {
      this.$router.push({ path: "instalacoes/" + installation });
    },
    showFilter() {
      this.visibleFilter = !this.visibleFilter;
    },
    updateArrayFilter(event) {
      if (event.target.checked) {
        this.arrayFilter.push(event.target.value);
      } else {
        for (var i = 0; i < this.arrayFilter.length; i++) {
          if (this.arrayFilter[i] == event.target.value) {
            this.arrayFilter.splice(i, 1);
          }
        }
      }
    },
    applyFilters() {
      if (this.arrayFilter.length > 0) {
        this.filteredInstallations = this.sortedInstallationsByDistance.filter(
          (el) => {
            return this.compareArrays(el.dealerServices, this.arrayFilter);
          }
        );
      } else {
        this.filteredInstallations = this.sortedInstallationsByDistance;
      }

      this.markers = [];
      this.filteredInstallations.forEach((installation) => {
        this.addMarker(installation);
      });
      this.visibleFilter = false;
    },
    compareArrays(arrayToFilter, baseFilter) {
      const filter = baseFilter.some((el1) => {
        return arrayToFilter.includes(el1);
      });

      return filter;
    },
  },
  watch: {
    google() {
      if (this.google) {
        this.checkLocationPermission();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Map";
</style>
