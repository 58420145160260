const geolib = require("geolib");

export function getDealersByRange(dealers, userLocation) {
  const sortedDealers = [];

  dealers.forEach((dealer) => {
    const lat = dealer.lat;
    const lng = dealer.lng;
    const dealerLocation = { lat, lng };
    const distance = userLocation
      ? getMatrixDistance(dealerLocation, userLocation)
      : "";
    if (userLocation) {
      if (!distance) return null;
    }
    const service = {
      dealerID: dealer.id,
      dealerName: dealer.name,
      dealerAddress: dealer.address,
      dealerFullName: dealer.fullName,
      dealerDistance: userLocation ? Math.round(distance / 1000) : "", // m to km
      dealerCoordinates: `${lat},${lng}`,
      lat: lat,
      lng: lng,
      dealerServices: dealer.services,
    };

    sortedDealers.push(service);
  });
  if (userLocation) {
    sortedDealers.sort((a, b) =>
      a.dealerDistance > b.dealerDistance ? 1 : -1
    );
  }
  return sortedDealers;
}

function getMatrixDistance(origins, destinations) {
  if (origins.lat && origins.lng) {
    let result = geolib.getDistance(origins, destinations, 1);
    return result;
  }
  return null;
}
