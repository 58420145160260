<template>
  <div class="fluid-element">
    <div id="map">
      <gmap-map
        :center="center"
        ref="gmap"
        :options="mapStyle"
        height="600px"
        width="100%"
      >
        <gmap-marker :position="userMarker" :icon="iconDealer"></gmap-marker>
        <gmap-marker
          v-for="(marker, index) in markers"
          :key="index"
          :position="marker.position"
          :icon="{ url: getColoredIconUrl(index + 1) }"
          @click="goToInstallation(marker.installationID)"
        ></gmap-marker>
      </gmap-map>
    </div>
  </div>
</template>

<script>
import dealerMarker from "@/assets/pin.svg";

import mapStyle from "@/data/mapStyle.json";
import { getColoredIconUrl } from "@/functions/createMarker.js";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "Map",
  props: {
    userMarker: { type: Object },
    markers: { type: Array },
  },
  data() {
    return {
      center: { lat: 41.1177, lng: -8.596 },
      iconDealer: { url: dealerMarker },
      mapStyle: {
        styles: mapStyle,
        disableDefaultUI: true,
        zoom: 13,
        maxZoom: 20,
      },
      bounds: {},
    };
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    goToInstallation(id) {
      this.$emit("goToInstallation", id);
    },
    createBounds() {
      this.bounds = new this.google.maps.LatLngBounds();

      this.markers.forEach((el) => {
        this.bounds.extend(el.position);
      });

      this.$refs.gmap.$mapPromise.then((map) => {
        map.fitBounds(this.bounds);
      });
    },
    getColoredIconUrl,
  },
  watch: {
    markers() {
      if (this.google) {
        this.createBounds();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Map";
</style>
