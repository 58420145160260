<template>
  <div class="wrapper">
    <Section>
      <HeadingBlock :data="instalacoesContent" />
    </Section>
    <Section>
      <MapContainer />
    </Section>
    <Section v-if="seo">
      <SEO
        :seo="seo.general"
        :banner="seo.general.seoBannerImage"
        :link="seo.general.seoBannerLink"
      />
    </Section>
    <CTA />
  </div>
</template>

<script>
import CTA from "@/components/CTA/CTA.vue";
import MapContainer from "@/components/Map/MapContainer.vue";
import Section from "@/components/Section/Section.vue";
import SEO from "@/components/SEO/SEO.vue";
import HeadingBlock from "@/components/Blocks/HeadingBlock.vue";

import { manageSEO } from "@/services/dataManagement.js";

export default {
  name: "Dealers",
  metaInfo() {
    return manageSEO(this.seo, this.seoHomepage, false, this.dealerName);
  },
  components: {
    CTA,
    MapContainer,
    Section,
    SEO,
    HeadingBlock,
  },
  data() {
    return {
      dealerName: this.$store.state.dealerInfo.dealerName,
    };
  },
  computed: {
    instalacoesContent() {
      return this.$store.state.instalacoesContent;
    },
    seo() {
      return this.$store.state.instalacoesSEO;
    },
    seoHomepage() {
      return this.$store.state.homepage.seo.general;
    },
  },
};
</script>
