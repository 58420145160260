<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#FF0022" />
    <text
      fill="white"
      font-family="Open Sans, sans-serif"
      font-weight="700"
      x="50%"
      y="70%"
      text-anchor="middle"
    >
      {{ number }}
    </text>
  </svg>
</template>

<script>
export default {
  props: {
    number: { type: Number, default: 1 },
  },
};
</script>
